// 公用api
import request from '@/tools/request';

// 保存降价通知设置
export function savebase(data) {
  return request({
    url: `/pricecutsremind-config-savebase.html`,
    method: 'post',
    data,
  });
}
// 获取配置详情
export function getdetail(data) {
  return request({
    url: `/pricecutsremind-config-detail.html`,
    method: 'get',
    params: data,
  });
}
