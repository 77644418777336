<template>
  <div class="home">
    <header>
      <m-page-header title="降价通知设置" />
    </header>
    <main class="main">
      <div class="chackbox">
        <el-checkbox v-model="checked.cart_remind">购物车商品降价自动通知</el-checkbox>
      </div>
      <div class="chackbox">
        <el-checkbox v-model="checked.manual_remind">商品降价订阅</el-checkbox>
      </div>
      <el-form size="medium" ref="ruleForm" :model="formJson" :rules="rules">
        <div class="from" v-if="checked.manual_remind">
          <el-form-item label="订阅名称" prop="page_title">
            <el-input v-model="formJson.page_title"></el-input>
          </el-form-item>
          <el-form-item label="订阅描述" prop="page_remark">
            <el-input type="textarea" rows="5" v-model="formJson.page_remark"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="归因天数" class="gyts" prop="attribution_days">
          <el-input v-model.number="formJson.attribution_days"></el-input>
        </el-form-item>
      </el-form>
    </main>
    <div class="save">
      <el-button type="primary" :loading="loading" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getdetail, savebase } from '../api/PricecutsremindSetting';
import { ElMessage } from 'element-plus';
export default {
  name: 'PricecutsremindSetting',
  setup() {
    const checked = ref({
      cart_remind: false,
      manual_remind: false,
    });
    const loading = ref(false);
    const formJson = ref({
      cart_remind: '0', //购物车自动订阅开关（0=关闭 1=开启）
      manual_remind: '0', //手动订阅开关（0=关闭 1=开启）
      page_title: '', //登记页面设置标题
      page_remark: '', //登记页面描述
      attribution_days: '', //归因天数
    });
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('归因天数不能为空或者为0'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value <= 0 || value > 999) {
            callback(new Error('请输入1~999之间的数字'));
          } else {
            callback();
          }
        }
      }, 100);
    };
    const rules = ref({
      page_title: [
        { required: true, message: '请输入订阅名称', trigger: 'blur', whitespace: true },
      ],
      page_remark: [
        { required: true, message: '请输入订阅描述', trigger: 'blur', whitespace: true },
      ],
      attribution_days: [{ validator: checkAge, required: true, trigger: 'blur' }],
    });
    async function getConfing() {
      const { data } = await getdetail();
      checked.value.cart_remind = Number(data.cart_remind) === 0 ? false : true;
      checked.value.manual_remind = Number(data.manual_remind) === 0 ? false : true;
      data.attribution_days = Number(data.attribution_days);
      formJson.value = data;
    }
    const ruleForm = ref();
    function save() {
      ruleForm.value.validate(valid => {
        if (valid) {
          loading.value = true;
          formJson.value.cart_remind = checked.value.cart_remind ? '1' : '0';
          formJson.value.manual_remind = checked.value.manual_remind ? '1' : '0';
          savebase(formJson.value)
            .then(() => {
              loading.value = false;
              ElMessage.success({
                message: '操作成功',
                type: 'success',
              });
              getConfing();
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
    return {
      checked,
      formJson,
      rules,
      loading,
      checkAge,
      ruleForm,
      save,
      getConfing,
    };
  },
  mounted() {
    this.getConfing();
  },
};
</script>

<style scoped lang="scss">
.main {
  padding: 20px;
  .chackbox {
    margin: 20px 0;
  }
  .from {
    background-color: #f6f6f6;
    padding: 20px;
    box-sizing: border-box;
  }
  .el-form-item {
    max-width: 450px;
  }
  .gyts {
    max-width: 470px;
  }
}
.save {
  padding: 10px 20px;
  background-color: #fafafa;
  button {
    min-width: 180px;
  }
}
</style>
